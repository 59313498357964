

// menu scripts
;(function ($) {

  "use strict";

  $('#menuButton').on('click', function(e) {
    $('body').toggleClass('menu-active');
  });

  $(document).on('click', function(e) {
    if (!$(e.target).closest('.nav').length && !$(e.target).closest('#menuButton').length) {
      $('body').removeClass('menu-active');
    }
  });

})(jQuery);
